<template>
    <div>
        <psi-form-element :required="required" :label="label">
            <psi-file-upload
                ref="file"
                v-model="media"
                :error.sync="error"
                @afterUpload="$emit('afterUpload', $event)"
            ></psi-file-upload>
        </psi-form-element>
    </div>
</template>
<script>
export default {
    name: "psi-form-media-upload",
    components: {
        PsiFileUpload: () => import("./PsiFileUpload"),
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        category: {
            type: String,
            required: false,
            default: "",
        },
        uuid: {
            type: String,
            required: false,
            default: "",
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        value: {
            type: [Object, Array],
            required: false,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            media: Object.assign({}, this.value),
            error: "",
        };
    },
    computed: {},
    watch: {
        media(value) {
            if (Object.keys(value).length > 0) {
                value[Object.keys(value).shift()].custom_properties = {
                    category: this.category,
                    uuid: this.uuid,
                };
            }
            this.$emit("input", value);
        },
    },
    methods: {
        validate() {
            const valid =
                (this.required && this.$refs.file.hasMedia()) || !this.required;
            if (!valid) {
                this.error = `${this.label} is required`;
            }
            return valid;
        },
    },
};
</script>

<style >
</style>