var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "psi-form-element",
        { attrs: { required: _vm.required, label: _vm.label } },
        [
          _c("psi-file-upload", {
            ref: "file",
            attrs: { error: _vm.error },
            on: {
              "update:error": function($event) {
                _vm.error = $event
              },
              afterUpload: function($event) {
                return _vm.$emit("afterUpload", $event)
              }
            },
            model: {
              value: _vm.media,
              callback: function($$v) {
                _vm.media = $$v
              },
              expression: "media"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }